import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DownloadEbook from 'assets/icons/download.svg';
import { DynamicImage, PrimaryButton, Text } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import BookImage from './BookImage';
import { CircularProgress } from '@material-ui/core';
import BirthChart from 'pages/start/quiz/components/BirthChart';
import useQuizAnswersBirthDetails from 'utils/hooks/useQuizAnswersBirthDetails';
import { Horoscope, Origin } from 'circular-natal-horoscope-js';
import quizSvgs from 'utils/constants/quizSvgs';
import CircleType from 'circletype';
import { resolveBookCoverName } from 'utils/hooks/resolveBookCoverName';

interface ThankYouSectionProps {
  title: string;
  subtitle: string;
  img: string;
  alt: string;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2rem;
  height: 452px;
  padding: 4.5rem 1rem;
  background: #f3f3f8;
  @media ${tablet} {
    padding: 2.25rem 1rem 2.25rem;
    flex-direction: column;
    height: auto;
    gap: 0rem;
  }
`;

const TYTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  gap: 3rem;
  @media ${tablet} {
    align-items: center;
    gap: 2rem;
    width: 100%;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  @media ${tablet} {
    gap: 0.5rem;
    align-items: center;
  }
`;

const TYTitle = styled(Text)`
  width: 100%;
  max-width: 487px;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 48px;
  text-align: left;
  strong {
    font-weight: 800;
    color: ${({ theme }) => theme.colors.primary};
  }
  @media ${tablet} {
    text-align: center;
    font-size: 1.5rem;
    max-width: 303px;
    line-height: 31.2px;
  }
`;

const TYText = styled(Text)`
  width: 100%;
  max-width: 418px;
  text-align: left;
  strong {
    font-weight: 600;
  }
  @media ${tablet} {
    text-align: center;
    max-width: 303px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  gap: 3.392rem;
  @media ${tablet} {
  }
`;

const DecoratePlusSign = styled(Text).attrs({
  color: 'dark100',
})`
  color: #f7c800;
  font-size: 40px;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
`;

const BookContainer = styled.div`
  position: relative;
  max-width: 18.7rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  div#paper,
  div#paper2 {
    position: absolute !important;
    top: 39px;
    right: 50%;
    transform: translateX(50%);
  }
  @media ${tablet} {
    margin-bottom: 1rem;
  }
`;

const EBookCoverPlus = styled(DynamicImage)`
  width: 7.375rem;
`;

const EbookContainerOnly = styled.div`
  display: flex;
  padding: 0.1295rem 0.51794rem;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -10px;
  border-radius: 0.27181rem;
  background: #f7c800;
  z-index: 3;
  right: 50%;
  transform: translateX(50%);
  color: #000;
  text-align: center;
  font-size: 1.03588rem;
  font-weight: 800;
`;

const EbookContainer = styled(EbookContainerOnly)`
  padding: 0.08556rem 0.34225rem;
  bottom: -5px;
  border-radius: 0.17963rem;
  font-size: 0.6845rem;
`;

const BookCover = styled(DynamicImage)`
  width: 7.6875rem;
`;

const DownloadButton = styled.a`
  display: flex;
  cursor: pointer;
  width: 17.5rem;
  height: 3rem;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 6.25rem;
  background: #f7c800;
  color: #000 !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  &:hover {
    background: #f7c800;
    opacity: 1 !important;
  }
`;

const DownloadButtonLoad = styled(DownloadButton)`
  background: rgb(247 200 0 / 60%);
  cursor: default;
  &:hover {
    background: rgb(247 200 0 / 60%);
    opacity: 1 !important;
  }
`;

const CircularProgressStyled = styled(CircularProgress)``;

const StyledDynamicImage = styled(DynamicImage)`
  width: 343px;
  background-size: contain;
  @media ${tablet} {
    width: 220px;
  }
`;

const BirthChartStyled = styled(BirthChart)`
  position: absolute !important;
  top: 0;
  div#paper {
    position: absolute !important;
    top: 0;
  }
`;

const SunSign = styled.div<{ color: string }>`
  display: flex;
  width: 17px;
  height: 17px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 23.386px;
  border: 0.532px solid ${({ color }) => color};
  position: absolute;
  bottom: 6px;
  left: 7px;
  gap: 1px;
`;

const SunSignMockup = styled(SunSign)`
  width: 30px;
  height: 30px;
  bottom: 15px;
  left: 46px;
  gap: 2px;
`;

const SunSignPlus = styled(SunSignMockup)`
  width: 17px;
  height: 17px;
  bottom: 5px;
  left: 6px;
`;

const MoonSign = styled(SunSign)`
  bottom: 6px;
  right: 8px;
  left: unset;
`;

const MoonSignPlus = styled(MoonSign)`
  bottom: 5px;
  right: 6px;
  left: unset;
`;

const SunText = styled.p<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  text-align: center;
  font-size: 2px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: 'Montserrat', sans-serif;
  max-width: 9px;
  position: relative;
  text-transform: uppercase;
`;

const SvgSign = styled.div`
  width: 6px;
  height: 6px;
  position: relative;
  svg {
    position: absolute;
  }
`;

const Name = styled.p<{
  color: string;
  isSurname: boolean;
  isNameLongerThan10: boolean;
  isSurameLongerThan10: boolean;
}>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  text-align: center;
  font-family: 'Arno Pro';
  font-size: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '7px' : '7px'};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '7px' : '7px'};
  letter-spacing: 0.6px;
  text-transform: uppercase;
  position: absolute;
  top: ${({ isSurname }) => (isSurname ? '1.65rem' : '1.85rem')};
  right: 50%;
  transform: translateX(50%);
  text-wrap: nowrap;
`;

const Date = styled.p<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  font-family: 'Montserrat';
  font-size: 4px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.1rem;
  position: absolute;
  bottom: 1.2rem;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100px;
  text-transform: capitalize;
`;

const DatePlus = styled(Date)`
  bottom: 1.45rem;
`;

const City = styled(Date)<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  bottom: 0.8rem;
`;
9;
const CityPlus = styled(City)`
  bottom: 1.1rem;
`;

const ImageWrapper = styled.div`
  position: relative;
  max-width: 220px;
  width: 100% !important;
  margin-right: 2.88rem;
  @media ${tablet} {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const ImageContainerOnly = styled.div`
  position: relative;
  width: 220px;
  div#paper3 {
    position: absolute !important;
    top: 70px;
    right: 50%;
    transform: translateX(50%);
  }
`;

const StyledDynamicImageOnly = styled(DynamicImage)`
  width: 220px;
`;

const NameOnly = styled.p<{
  color: string;
  isSurname: boolean;
  isNameLongerThan10: boolean;
  isSurameLongerThan10: boolean;
}>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  text-align: center;
  font-family: 'Arno Pro';
  font-size: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '11px' : '14px'};
  line-height: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '12px' : '14px'};
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  text-wrap: nowrap;
  ${({ isSurname }) => !isSurname && 'top: 3.3rem;'}
  ${({ isSurname, isNameLongerThan10, isSurameLongerThan10 }) =>
    isSurname && (isNameLongerThan10 || isSurameLongerThan10) && 'top: 3rem;'}
  ${({ isSurname, isNameLongerThan10, isSurameLongerThan10 }) =>
    isSurname &&
    !(isNameLongerThan10 || isSurameLongerThan10) &&
    'top: 2.9rem;'}
  
  letter-spacing: 1.5px;
`;

const DateOnly = styled.p<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  font-family: 'Montserrat';

  font-style: normal;
  font-weight: 500;
  line-height: normal;

  position: absolute;

  right: 50%;
  transform: translateX(50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  text-transform: capitalize;

  font-size: 6px;
  width: 100px;
  bottom: 2.85rem;
  letter-spacing: 0.2rem;
`;

const CityOnly = styled(DateOnly)<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  bottom: 2rem;
`;

const SunSignOnly = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 59px;
  border: 0.532px solid ${({ color }) => color};
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: 9px;
  left: 12px;
  gap: 1px;
`;

const MoonSignOnly = styled(SunSignOnly)`
  left: unset;
  bottom: 9px;
  right: 12px;
`;
const SunTextOnly = styled.p<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  text-align: center;

  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  text-transform: uppercase;
  font-size: 4px;
  max-width: 19px;
`;

const SvgSignOnly = styled.div`
  width: 10px;
  height: 10px;
  position: relative;
  svg {
    position: absolute;
  }
`;

const Ebook = styled.p`
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  color: #000;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 0.52863rem;
  background: #f7c800;

  padding: 0.11844rem 0.47369rem;
  font-size: 1.01463rem;
  max-width: 4.07rem;
  bottom: -10px;
`;

const ThankYouSection: FC<ThankYouSectionProps> = ({
  img,
  subtitle,
  title,
  alt,
}) => {
  const { isTablet } = useQuery();
  const [showName, setShowName] = useState<boolean>(false);
  const { user } = useSelector((state: AppState) => state);
  const selectedPlans = user?.selected_plans;

  const buildValueKey = (item: string) =>
    item.toLowerCase().replace(/\s+/g, '_');

  const ebookCheck = selectedPlans
    ?.filter(item => !item.is_subscription)
    .some(item => item.type === 'digital');
  const paperBook = selectedPlans
    ?.filter(item => !item.is_subscription)
    .some(item => item.type === 'physical');

  const resolvePageTextContent = () => {
    if (ebookCheck && !paperBook) {
      return {
        title: 'Your ebook is being prepared! 🎉',
        subtitlePlaceHolder: 'It will be delivered to your email.',
        subtitle: 'It will be delivered to your email.',
        downloadButton: 'Download my ebook',
      };
    }
    if (!ebookCheck && paperBook) {
      return {
        title: 'Your physical book is being prepared! 🎉',
        subtitle: 'It should arrive in 8-16 business days',
      };
    }
    return {
      title: 'Your physical & ebook are being prepared! 🎉',
      subtitle:
        'Your physical book should arrive in 8-16 business days and ebook will be sent to your email',
      downloadButton: 'Download my ebook',
    };
  };
  const quizAnswers = useSelector((state: AppState) => state.user.quiz_answers);

  const color = quizAnswers?.color;
  const contentColor = quizAnswers?.contentColor;

  const birthDetails = useQuizAnswersBirthDetails();

  const renderSvgImage = (svgKey: string) => {
    const Svg = quizSvgs[svgKey];
    return <Svg />;
  };

  const textRefPlace = useRef<HTMLDivElement>(null);
  const textRefPlace2 = useRef<HTMLDivElement>(null);
  const textRefPlace3 = useRef<HTMLDivElement>(null);
  const textRefDate = useRef<HTMLDivElement>(null);
  const textRefDate2 = useRef<HTMLDivElement>(null);
  const textRefDate3 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRefPlace.current) {
      new CircleType(textRefPlace.current).dir(-1).radius(60);
    }
    if (textRefDate.current) {
      new CircleType(textRefDate.current).dir(-1).radius(40);
    }
    if (textRefPlace2.current) {
      new CircleType(textRefPlace2.current).dir(-1).radius(60);
    }
    if (textRefDate2.current) {
      new CircleType(textRefDate2.current).dir(-1).radius(40);
    }
    if (textRefPlace3.current) {
      new CircleType(textRefPlace3.current).dir(-1).radius(150);
    }
    if (textRefDate3.current) {
      new CircleType(textRefDate3.current).dir(-1).radius(120);
    }
  });

  const handleOnLoad = () => {
    setShowName(true);
  };

  const { isNameLongerThan10, isSurameLongerThan10, isSurname, formattedName } =
    resolveBookCoverName(quizAnswers?.name, quizAnswers?.surname);

  return (
    <Container>
      {ebookCheck && paperBook ? (
        <ImageContainer>
          <BookContainer>
            <BookCover
              alt="Nordastro cover"
              src={`books-mockups/${quizAnswers.color}.png`}
              // handleOnLoad={handleOnLoad}
            />
            <BirthChartStyled
              year={birthDetails?.year as number}
              month={birthDetails?.month as number}
              day={birthDetails?.day as number}
              hour={birthDetails?.hours as number}
              minute={birthDetails?.minutes as number}
              latitude={quizAnswers?.birth_place.lat}
              longitude={quizAnswers?.birth_place.lng}
              color={contentColor}
              width={90}
              height={90}
            />
            <Name
              color={color}
              dangerouslySetInnerHTML={{ __html: formattedName }}
              isSurname={isSurname}
              isNameLongerThan10={isNameLongerThan10}
              isSurameLongerThan10={isSurameLongerThan10}
            />
            <DatePlus color={color} ref={textRefDate}>
              {birthDetails && birthDetails.fullDate}
            </DatePlus>

            <CityPlus color={color} ref={textRefPlace}>
              {`${quizAnswers?.birth_place.main_text}, ${quizAnswers?.birth_place.secondary_text}`}
            </CityPlus>
            <SunSign color={contentColor}>
              <SunText color={color}>SUN SIGN</SunText>
              <SvgSign>
                {renderSvgImage(`${color}${quizAnswers?.zodiacSign}`)}
              </SvgSign>
            </SunSign>
            <MoonSign color={contentColor}>
              <SunText color={color}>MOON SIGN</SunText>
              <SvgSign>
                {renderSvgImage(`${color}${quizAnswers?.planets.moon}`)}
              </SvgSign>
            </MoonSign>
            {showName && <></>}
          </BookContainer>
          <DecoratePlusSign>+</DecoratePlusSign>
          <BookContainer>
            <EBookCoverPlus
              alt="Nordastro cover"
              src={`booksCovers/${quizAnswers.color}.png`}
              // handleOnLoad={handleOnLoad}
            />
            <BirthChartStyled
              year={birthDetails?.year as number}
              month={birthDetails?.month as number}
              day={birthDetails?.day as number}
              hour={birthDetails?.hours as number}
              minute={birthDetails?.minutes as number}
              latitude={quizAnswers?.birth_place.lat}
              longitude={quizAnswers?.birth_place.lng}
              color={contentColor}
              width={90}
              height={90}
              divId="paper2"
            />
            <Name
              color={color}
              dangerouslySetInnerHTML={{ __html: formattedName }}
              isSurname={isSurname}
              isNameLongerThan10={isNameLongerThan10}
              isSurameLongerThan10={isSurameLongerThan10}
            />
            <Date color={color} ref={textRefDate2}>
              {birthDetails && birthDetails.fullDate}
            </Date>

            <City color={color} ref={textRefPlace2}>
              {`${quizAnswers?.birth_place.main_text}, ${quizAnswers?.birth_place.secondary_text}`}
            </City>
            <SunSignPlus color={contentColor}>
              <SunText color={color}>SUN SIGN</SunText>
              <SvgSign>
                {renderSvgImage(`${color}${quizAnswers?.zodiacSign}`)}
              </SvgSign>
            </SunSignPlus>
            <MoonSignPlus color={contentColor}>
              <SunText color={color}>MOON SIGN</SunText>
              <SvgSign>
                {renderSvgImage(`${color}${quizAnswers?.planets.moon}`)}
              </SvgSign>
            </MoonSignPlus>
            {/* {showName && (
              <> */}
            <EbookContainer>ebook</EbookContainer>
            {/* </>
            )} */}
          </BookContainer>
        </ImageContainer>
      ) : (
        <ImageWrapper>
          <ImageContainerOnly>
            <StyledDynamicImageOnly
              src={`booksCovers/${color}.png`}
              alt=""
              // handleOnLoad={handleOnLoad}
            />
            <BirthChartStyled
              year={birthDetails?.year as number}
              month={birthDetails?.month as number}
              day={birthDetails?.day as number}
              hour={birthDetails?.hours as number}
              minute={birthDetails?.minutes as number}
              latitude={quizAnswers?.birth_place.lat}
              longitude={quizAnswers?.birth_place.lng}
              color={contentColor}
              width={155}
              height={155}
              divId="paper3"
            />
            <NameOnly
              color={color}
              dangerouslySetInnerHTML={{ __html: formattedName }}
              isSurname={isSurname}
              isNameLongerThan10={isNameLongerThan10}
              isSurameLongerThan10={isSurameLongerThan10}
            />
            <DateOnly color={color} ref={textRefDate3}>
              {birthDetails && birthDetails.fullDate}
            </DateOnly>

            <CityOnly color={color} ref={textRefPlace3}>
              {`${quizAnswers?.birth_place.main_text}, ${quizAnswers?.birth_place.secondary_text}`}
            </CityOnly>
            <SunSignOnly color={contentColor}>
              <SunTextOnly color={color}>SUN SIGN</SunTextOnly>
              <SvgSignOnly>
                {renderSvgImage(`${color}${quizAnswers?.zodiacSign}`)}
              </SvgSignOnly>
            </SunSignOnly>
            <MoonSignOnly color={contentColor}>
              <SunTextOnly color={color}>MOON SIGN</SunTextOnly>
              <SvgSignOnly>
                {renderSvgImage(`${color}${quizAnswers?.planets.moon}`)}
              </SvgSignOnly>
            </MoonSignOnly>
          </ImageContainerOnly>
          {ebookCheck && !paperBook && <Ebook>ebook</Ebook>}
        </ImageWrapper>
      )}
      <TYTextContainer>
        <TextContainer>
          <TYTitle
            color="dark100"
            dangerouslySetInnerHTML={{ __html: resolvePageTextContent().title }}
          />
          <TYText
            type={isTablet ? 'body' : 'body'}
            color="dark100"
            dangerouslySetInnerHTML={{
              __html: resolvePageTextContent().subtitle,
            }}
          />
          {user?.selected_plans_options?.ebookCheck &&
            (quizAnswers?.ebookUrl ? (
              <DownloadButton target="_blank" href={quizAnswers?.ebookUrl}>
                <DownloadEbook />
                {resolvePageTextContent()?.downloadButton}
              </DownloadButton>
            ) : (
              <DownloadButtonLoad>
                <CircularProgressStyled style={{ color: '#000' }} size={20} />
              </DownloadButtonLoad>
            ))}
        </TextContainer>
      </TYTextContainer>
    </Container>
  );
};

export default ThankYouSection;
