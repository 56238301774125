import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, PrimaryButton, Seo } from 'components';
import { AppState } from 'state/types';
import HowToSection from './components/HowToSection';
import ThankYouSection from './components/ThankYouSection';
import OrderSummary from './components/OrderSummary';
import Header from 'components/Header';
import { usePageView, useQuizData } from 'utils/hooks';
import Tracking from 'utils/tracking';
import Disclaimer from 'components/Disclaimer';
import PaymentSteps from '../payments/components/PaymentSteps';
import { tablet } from 'styles/breakpoints';
import { normalizeStates } from 'utils/localization';
import { config } from 'config';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const PaymentStepsContainer = styled.div<{ isWhite: boolean }>`
  display: flex;
  justify-content: center;
  padding: 2.375rem 1rem 1.75rem;
  width: 100%;
  background-color: ${({ isWhite }) => (isWhite ? '#fff' : '#f3f3f8')};
  @media ${tablet} {
    padding: 2rem 1rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  width: 100%;
`;

const DownloadContainer = styled.div<{ isWhite: boolean }>`
  width: 100%;
  background-color: ${({ isWhite }) => (isWhite ? '#fff' : '#f3f3f8')};
  padding: 2.375rem 1rem 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${tablet} {
    padding: 0 1rem 2.375rem;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DynamicImageStyled = styled(DynamicImage)`
  max-width: 26.79688rem;
  height: 20rem;
  width: 100%;
  @media ${tablet} {
    height: unset;
  }
`;

const Title = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 34.0625rem;
  padding: 1.5rem 0;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }
`;

const ButtonStyled = styled(PrimaryButton)`
  max-width: 28.75rem;
  width: 100%;
`;

const Success: FC<RouteComponentProps> = () => {
  const code = useSelector((state: AppState) => state.user.code);
  const data = useQuizData('success');

  const {
    selected_plans_options,
    upsell_products,
    geolocation,
    quiz_answers,
    user,
  } = useSelector((state: AppState) => state.user);

  const { subContainer } = selected_plans_options;
  const hasBoughtUpsellApp = upsell_products.some(
    obj => obj['is_subscription'] === true,
  );
  usePageView({
    country: geolocation?.iso_country?.toLowerCase() || 'no-country-fallback',
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    email: user?.email.trim() || 'no-email-fallback',
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
    client_code: code,
    zipCode: quiz_answers?.shipping_address?.postcode?.toLowerCase(),
    phoneNumber: quiz_answers?.shipping_address?.phoneNumber,
  });

  useEffect(() => {
    if (!code) {
      return;
    }

    Tracking.logEvent({
      event: 'email_left',
      client_code: code,
    });
  }, [code]);

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header logoVariant="center" hasDivider={true} color="coldGrey" />
      <Container>
        {code ? (
          <>
            <PaymentStepsContainer isWhite={false}>
              <PaymentStepsStyled {...data?.paymentSteps} />
            </PaymentStepsContainer>
            {subContainer || hasBoughtUpsellApp ? (
              <DownloadContainer isWhite={false}>
                <ImgContainer>
                  <DynamicImageStyled
                    src="upsell/app.png"
                    alt="Nordastro app"
                  />
                  <Title>{data?.appTitle}</Title>
                  <ButtonStyled
                    onClick={() => window.open(config.WEB_APP_URL, '_system')}
                  >
                    {data?.appButtonText}
                  </ButtonStyled>
                </ImgContainer>
              </DownloadContainer>
            ) : (
              <ThankYouSection {...data?.section1} />
            )}

            <OrderSummary {...data?.section3} isWhite={true} />
          </>
        ) : (
          <DownloadContainer isWhite={false}>
            <ImgContainer>
              <DynamicImageStyled src="upsell/app.png" alt="Nordastro app" />
              <Title>{data?.appTitle}</Title>
              <ButtonStyled
                onClick={() => window.open(config.WEB_APP_URL, '_system')}
              >
                {data?.appButtonText}
              </ButtonStyled>
            </ImgContainer>
          </DownloadContainer>
        )}
      </Container>
      <Disclaimer bgColor="#f3f3f8" text={data?.text} />
    </>
  );
};

export default Success;
